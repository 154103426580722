@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid;
  border-color: transparent transparent #20336b #20336b;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 70px;
  height: 70px;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.LoginMobileInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.LoginMobileInput input[type="number"] {
  -moz-appearance: textfield;
}

.policy-card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #1976d2;
  margin: 20px 0;
}

.card-header-custom {
  background-color: #1976d2;
  color: white;
  padding: 15px;
  font-size: 1.2em;
  border-bottom: 3px solid #1565c0;
  border-radius: 10px 10px 0 0;
}

.policy-section {
  display: flex;
  flex-direction: column;
}

.policy-scroll {
  overflow-y: auto;
  max-height: 600px;
}

.policy-table {
  margin-bottom: 20px;
}

.policy-table-title {
  background-color: #f0f0f0;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  border: 1px solid black;
  text-align: center;
  font-size: 20px;
}

.policy-table-content {
  overflow-x: auto;
}

.policy-table-content table {
  width: 100%;
  border-collapse: collapse;
}

.policy-table-content th {
  border: 1px solid #0f0f0f;
  padding: 8px;
  text-align: left;
  background-color: #1976d2; /* Background color for table headers */
  color: white;
  font-weight: 500;
}

.policy-table-content td {
  border: 1px solid #0f0f0f;
  padding: 8px;
  text-align: left;
}

.table-centered th,
.table-centered td {
  text-align: center;
  vertical-align: middle; /* This aligns the content vertically in the middle */
}

.table-centered {
  margin: 0 auto; /* Center the table itself if needed */
}

.policy-new {
  background-color: #c3e8c8; /* Light green */
}

.policy-current {
  background-color: #add8e6; /* Light blue */
}

.policy-old {
  background-color: #f9a8a8; /* Light red */
}

@media (max-width: 768px) {
  .policy-table-content table {
    min-width: 100%;
  }
}

.card-footer {
  padding: 8px 16px 16px 16px;
  text-align: center;
  background-color: var(--card-background);
  border-top: solid 2px var(--card-border);
  font-size: 16px;
  color: #464457;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px 10px 0px 10px;
}

.card-header > span {
  font-size: 16px;
  padding: 6px;
  display: inline-block;
  font-weight: bold;
}

.card-header {
  font-size: 16px;
  padding: 6px;
  display: inline-block;
  font-weight: bold;
}

label {
  font-weight: bold;
  /* display: flex;
  justify-content: flex-end; */
}

.card {
  margin: 10px 10px 10px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--card-border);
  background-color: var(--card-background);
  color: var(--card-color);
}

/* .card-header {
  background-color: var(--card-header-color-rgb);
  color: #333;
} */

.projcard {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #ddd;
  overflow: hidden;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.66);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}
.projcard:hover {
  box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
  transform: translate(0px, -3px);
}

.SubClaimCard {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #ddd;
  overflow: hidden;
  box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.66);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  border: 1px solid blue;
}

.SubClaimCard .card-header {
  background-color: #00baf2;
  color: black;
  text-shadow: 1px 1px 4px white;
}

.btn-tapti {
  font-size: 18px;
  padding: 0 16px;
  color: #e4e9f7;
  font-weight: 550;
  font-family: "Inter", sans-serif;
  background-color: #20336b;
  box-shadow: 1px 4px 5px 0 rgba(48, 48, 48, 0.45);
  border-radius: 24px;
  border: 1px solid #303030;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.btn-tapti:hover {
  color: black;
  background-color: rgb(130, 194, 251);
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 4px 5px 0 #20336b;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .btn-tapti {
    font-size: 14px;
    padding: 0px 10px;
    height: 34px;
  }
}

@media (max-width: 1000px) {
  .btn-tapti {
    font-size: 13px;
    padding: 0px 10px;
    height: 34px;
  }
}

@media (max-width: 983px) {
  .btn-tapti {
    font-size: 16px;
    padding: 0px 12px;
    height: 34px;
  }
}

@media (max-width: 768px) {
  .btn-tapti {
    font-size: 18px;
    padding: 0 10px;
    height: 40px;
  }
}

@media (max-width: 668px) {
  .btn-tapti {
    font-size: 18px;
    padding: 0 16px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .btn-tapti {
    font-size: 16px;
    padding: 0 8px;
    height: 36px;
    border-radius: 20px;
  }
}

.MainCardHeaderCss {
  font-size: 25px;
  font-weight: 900;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}

.MainCardHeaderCss span {
  font-size: 20px;
  font-weight: 900;
}

.scrollable-card-body {
  max-height: 85vh; /* Set a fixed height for the card body */
  overflow-y: auto; /* Enable vertical scrolling */
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.typeHade .dropdown-item {
  color: rgb(0, 0, 0);
  font-weight: 400;
  margin-left: 5px;
  padding: 10px;
}

.typeHade .dropdown-menu {
  margin-top: 0.5rem;
}

/* .navbar .typeHade .dropdown-menu {
  overflow: hidden !important;
  background-color: #00baf2;
} */

.typeHade .dropdown-menu {
  overflow: hidden !important;
  background-color: #fbfbfb;
}

.typeHade .dropdown-item:hover {
  color: black;
  background-color: #20336b;
  color: white;
}

.rbt-highlight-text {
  color: black !important;
  font-weight: bold;
  padding: 0;
  padding: 0.1875em;
  background-color: rgb(250, 250, 198) !important;
}

.CourierTable {
  width: 100%;
  border-collapse: collapse;
}

.CourierTable th,
.CourierTable td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
  word-break: break-word; /* Ensure long words wrap */
  overflow-wrap: anywhere; /* Ensure long words wrap in supported browsers */
}

/* Responsive styles for small screens */
@media (max-width: 576px) {
  .CourierTable,
  .CourierTable tbody,
  .CourierTable tr,
  .CourierTable td {
    display: block;
    width: 100%;
  }

  .CourierTable tr {
    margin-bottom: 10px;
  }

  .CourierTable td {
    display: block;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    border: none;
  }

  .CourierTable td::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
}

@media (max-width: 750px) {
  .TextBoxRow .col {
    margin: 10px;
  }
}

@media (max-width: 990px) {
  .TextBoxCol {
    margin: 5px;
  }
}

.input-group-text {
  font-size: 16px !important;
}

.dropdown-item {
  margin-bottom: 5px !important;
}


.form-label{
  font-size: medium;
}


.dropdown-menu {
  background-color: #555555;
  padding: 15px;
}


.inputBox {
  position: relative;
}

.inputBox {
  position: relative;
}

.inputBox input {
  padding: 12px 10px 5px 12px;
  border: none;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(5, 0, 0, 0.1);
  color: #000000;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.5s;
  outline: none;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 0;
  pointer-events: none;
  font-size: 1em;
  font-weight: 700;
  transition: 0.5s;
  letter-spacing: 0.05em;
  color: #000000;
  border: 1px solid #d8d8d8;
  background: #ffffff;
  transform: translateX(25px) translateY(-7px);
  font-size: 14px;
  padding: 0px 20px;
  border-radius: 10px;
  letter-spacing: 0.1em;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .inputBox input {
    font-size: 15px;
  }

  .inputBox span {
    font-size: 12px;
    padding: 0px 10px;
  }
}

@media (max-width: 992px) {
  .inputBox input {
    font-size: 14px;
    margin-top: 10px;
  }

  .inputBox span {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .inputBox input {
    font-size: 13px;
    padding: 10px 8px 4px 10px;
    margin-top: 10px;
  }

  .inputBox span {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .inputBox input {
    font-size: 12px;
    padding: 8px 6px 3px 8px;
  }

  .inputBox span {
    font-size: 0.8em;
  }
}

@media (max-width: 400px) {
  .inputBox input {
    font-size: 11px;
    padding: 6px 4px 2px 6px;
  }

  .inputBox span {
    font-size: 0.75em;
  }
}
